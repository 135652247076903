<template>
	<div style="width:100%; font-size: 12px;">
		<div style="position:relative; padding:0 30px">
			<div class="vline"></div>

			<div class="steptitle">
				<div class="steppoint"></div>
				流程发起人【{{$store.state.teacher.username}}】
			</div>
			<div class="nodecontent" v-for="(p, idx) in StepList" :key="idx">

				<div class="steppoint"></div>

				<div>
					<div v-if="p.sp_type == 'one' || p.sp_type == 'yuxuan'">
						<div class="steptitle">
							<!-- 指定人审批 -->
							系领导确认审批
						</div>


						<div class="spuser" v-for="(u, i) in p.target" :key="i">

							<img :src="u.avatar || temphead" alt=""> {{ u.username }}
						</div>

					</div>
					<div v-if="p.sp_type == 'dept_leader'">
						<div class="steptitle">
							<!-- 部门领导 -->
							教研室主任审批
						</div>
						<div class="spuser" v-for="(u, i) in p.target" :key="i">
							<img :src="u.avatar || temphead" alt=""> {{ u.username }}
						</div>
					</div>
					<div v-if="p.sp_type == 'sp_dept'">
						<div class="steptitle">
							审批人领导
						</div>
					</div>
					<div v-if="p.sp_type == 'notsure'" @click="choseSpUser(p, idx)">

						<div class="steptitle">

							<span>自选</span>

						</div>



						<div class="spuser" v-if="idx == 0 && EditItem.sp_users && EditItem.sp_users.length > 0"
							v-for="(u, i) in EditItem.sp_users " :key="'spu' + i">
							<img :src="u.avatar || temphead" alt=""> {{ u.username }}
						</div>

					</div>
					<div v-if="p.sp_type == 'dept_role'">
						<div class="steptitle" v-for="(u, i) in p.target" :key="i">
							{{ u.dept_name }}-{{ u.role_name }}
						</div>
					</div>

					<div v-if="p.sp_type == 'sys_role'">
						<div class="steptitle" v-for="(u, i) in p.target" :key="i">
							角色-{{ u.role_name }}
						</div>

					</div>

					<div v-if="p.sp_type == 'fgxz'">
						<div class="steptitle">
							分管校长审批
						</div>


						<div class="spuser" v-for="(u, i) in p.target" :key="i">
							<img :src="u.avatar || temphead" alt=""> {{ u.username }}
						</div>
					</div>


				</div>


			</div>
			
			<div class="nodecontent" v-if="CCList.length > 0">
				<div class="steptitle">
					<div class="steppoint"></div>
					抄送

				</div>
				<div class="spuser" v-for="(u, i) in CCList" :key="i">
					<img :src="u.avatar || temphead" alt=""> {{ u.username }}
				</div>

			</div>

		</div>



		<deptuser module="zichan" :odata="odata" :preuserlist="preUserList" :type="EditItem.sp_type" v-if="DeptUserDialog" v-on:getUser="getUser" :mult="true">
		</deptuser>


	</div>
</template>


<script>
import deptuser from "./deptuser.vue"
export default {
	props: ["code","dept_id"],
	components: {
		deptuser
	},
	data() {
		return {
			temphead: "//school.mmteck.cn/static/temp.png",
			isShowModal: false,
			ccdialog: false,
			StepList: [],
			CCList: [],
			preUserList:[],//预选中的用户
			odata:[],
			processId: 0,
			temp: {
				jump_more: false,
				jump_me: false
			},
			stepName: {
				1: "一",
				2: "二",
				3: "三",
				4: "四",
				5: "五",
				6: "六",
				7: "七",
				8: "八",
				9: "九"
			},
			EditItem: {
				id: 0,
				step_type: "and",
				process_id: 0,
				sp_type: "",
				step: "",
				sp_users: [],
				remark: "",
				can_end: 0,
			},
			DeptUserDialog: false,
			teacherInfo:{}
		}
	},
	watch: {
		code: function (n, o) {
			this.getProcess()
		},
		dept_id: function (n, o) {
			this.getProcess()
		}
	},

	mounted() {
		this.getProcess()
		
	},
	methods: {
		
		getProcess() {
			this.$http.post("/api/form_process_one", {
				process_code: this.code,
				dept_id:this.dept_id
			}).then(res => {
				this.processId = res.data.id;
				this.StepList = res.data.steps;
				res.data.jump_me ? res.data.jump_me = true : res.data.jump_me = false;
				res.data.jump_more ? res.data.jump_more = true : res.data.jump_more = false;
				this.temp = res.data;
				this.CCList = res.data.cc
				let step = this.StepList[0]
				// step.step_type = "and"
				this.EditItem = step
				if (step.sp_type == 'notsure' || step.sp_type == "yuxuan") {
					this.$emit("ischooseuser", true) //需要选人
					
					if(step.target&&step.target.length>0){
						this.preUserList = step.target
						this.$emit("chooseUser", [])
					}else{
						this.$emit("chooseUser", []) //设置默认为空
					}
					
				}
			})
		},
		updateProcess() {
			this.$http.post("/api/form_process_temp_edit", this.temp).then(res => {

			})
		},
		showItem(p) {
			this.EditItem = JSON.parse(JSON.stringify(p))
			this.EditItem.sp_users = this.EditItem.target
			this.isShowModal = true
		},
		choseSpUser(item, idx) {
			if (idx == 0) {
				//this.EditItem = item;
				this.DeptUserDialog = true

			}
		},
		getUser(e) {

			if (e) {
				this.EditItem.sp_users = e

			}
			this.DeptUserDialog = false
			this.$emit("chooseUser", this.EditItem.sp_users) //设置默认为空

		},
		getCCUser(e) {
			if (e) {

				this.$http.post("/api/form_process_cc_save", {
					process_id: this.processId,
					users: e
				}).then(res => {
					if (res.data.code == 200) {
						this.$message.success("保存成功！")
					}
					this.getProcess()
				})
			}
			this.ccdialog = false
		},
		saveStep() {

			this.$http.post("/api/form_process_step_save", this.EditItem).then(res => {
				this.isShowModal = false
				this.getProcess()
			})
		},
		deleteCC() {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				this.$http.post("/api/form_process_cc_save", {
					process_id: this.processId,
					users: []
				}).then(res => {
					this.$message({
						type: 'success',
						message: "清空成功！"
					});
					this.getProcess()
				})



			}).catch(() => {

			});
		},
		deleteStep(item) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				this.$http.post("/api/form_process_step_delete", {
					id: item.id
				}).then(res => {
					this.$message({
						type: 'success',
						message: res.data.msg
					});
					this.getProcess()
				})


			}).catch(() => {

			});
		}

	}
}
</script>
<style scoped="scoped">
.centbox {

	border-top: 1px solid #ccc;
	margin: 0 auto;
	text-align: center;
	font-size: 14px;
	padding: 20px;
}

.nodeitem {
	margin: 0 auto;
	position: relative;
	z-index: 2;
}

.nodecdk {
	border: 2px solid rgba(255, 69, 0, 0.8);
	box-shadow: 0 0 5px 2px rgba(255, 69, 0, 0.8);
}

.nodetitle {

	text-align: left;
	padding: 5px 10px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	background-color: rgb(255, 148, 62);
	color: #FFFFFF;
}

.notedit {
	background-color: #999999;
	color: #FFFFFF;
}

.nodecontent {
	position: relative;
}



.btnadd {
	position: absolute;
	left: 50%;
	bottom: 30px;
	transform: translateX(-50%);
	z-index: 3;
	transform-origin: center;
}

.remove {
	width: 20px;
	height: 20px;
	color: #fff;
	float: right;
	text-align: center;
	line-height: 20px;
	cursor: pointer;
	opacity: 0;
}




.spuser {
	background-color: #fff;

	padding: 2px;
	margin-right: 10px;
	margin-bottom: 5px;
	border-radius: 4px;
	padding: 4px 10px;
	display: inline-block;
}

.steptitle {

	padding: 2px;
	margin-right: 10px;
	margin-bottom: 5px;
	border-radius: 4px;
	padding: 4px 10px;
	position: relative;
}

.steppoint {
	border: 4px solid lightblue;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	position: absolute;
	left: -22px;
	top: 5px;
	background-color: #fff;
}

.spuser img {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
}

.steptype {
	background-color: #058e71;
	font-size: 12px;
	padding: 2px 5px;
	margin-left: 10px;
	border-radius: 2px;

	display: inline-block;
}

.vline {
	width: 0px;
	height: 90%;
	left: 15px;
	top: 4%;
	position: absolute;
	border-left: 2px dotted lightblue;
	z-index: 0;
}
</style>
