<template>
  <div class="wraper pagesxshenling">
    <Table ref="SXchukuTable" pageFrom="chuku"></Table>
    <!--查看-->
    <SLDetail v-if="dialogVisible_detail" :dialogVisible_detail="dialogVisible_detail" :dialogTitle="dialogTitle" :EditItem1="EditItem"></SLDetail>

    <!--出库-->
    <CKVerify v-if="dialogVisible_verify_ck" :dialogVisible_verify_ck="dialogVisible_verify_ck"
              :dialogTitle="dialogTitle" :EditItem1="EditItem"></CKVerify>
    <!--预览图片-->
    <el-dialog width="600px" :visible.sync="dialogVisibleImageUrl" @close="dialogVisibleImageUrl = false"
               :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>



    <!--打印单-->
    <el-dialog :title="dialogTitle" :visible="dialogVisible_print" :close-on-click-modal="false" width="900px"
               custom-class="cus_dialog cus_dialog_print"
               @close="dialogVisible_print=false">
      <div style="text-align: right; position: absolute; top:2px; right: 50px;">
        <el-button v-print="'#printMe'" icon="el-icon-printer" type="warning">打印</el-button>
      </div>
      <SLPrintForm v-if="dialogVisible_print" :printId="printId"></SLPrintForm>

    </el-dialog>
  </div>
</template>

<script>
import processchoose from "../../com/processchsmini";

export default {
  components: {
    processchoose,
    Table: () => {
      return import('./com_sxshenling/SXShenLTable.vue')
    },
    SLDetail: () => {
      return import('./com_sxshenling/SLDetail.vue')
    },
    CKVerify: () => {
      return import('./com_sxshenling/CKVerify.vue')
    },
    SLPrintForm: () => {
      return import('../../../h5/print/sl.vue')
    },

  },
  data() {
    return {
      //--审批流程用----
      process_code: "hcsl",
      process_id: 0,
      needChoose: false,//是否需要选择下级
      choosedUser: [],//已选择的用户
      //--审批流程用----
      curDate: '',
      userinfo: {},
      searchForm: {},
      dialogTitle: '新增',
      dialogVisible_add: false,
      dialogVisible_sxType: false,
      EditItem: {arr_json: ''},
      arr_json: [],
      dialogVisibleImageUrl: false,
      dialogImageUrl: '',//预览图片
      dialogVisible_verify_ck: false,
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },

      disabled: false,
      files: [],
      logList: [],
      temp_list: [],//todo 未用备用
      showUserDialog: false,
      userDialogType: null,
      drawer: false,//高级搜索面板
      ErrLog: [],
      page_hc: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      purchaseType: [],
      dialogVisible_print:false,
      printId:'',
      dialogVisible_detail:false,
    }
  },
  mounted() {
    this.getPurchaseType()
    this.getCurDate();
    this.getCreateUser()
    //--审批流程用----
    this.getProcessID()
  },
  methods: {
    //--审批流程用----
    isChooseuser(e) {//设置是否需要选人
      this.needChoose = e
    },
    chooseUser(e) {//设置选择的人
      this.choosedUser = e
    },
    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    //--审批流程用----
    getList() {
      let _this = this
      let params = {}, arr = []
      params.title = _this.searchForm.title
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.type = 'sx'
      this.$http.post("/api/sx_sg_list", params).then(res => {
        this.DataList = res.data.data;
        if (res.data && res.data.page) {
          this.page = res.data.page
          this.page_hc = res.data.page
        }

      })
    },
    getCreateUser() {
      this.$http.post("/api/get_teacher_info").then(res => {
        this.userinfo = res.data
      })
    },
    getPurchaseType() {
      this.$http.post("/api/sys_dictionary_list", {key_type: "purchaseType"}).then(res => {
        if (res.data && res.data.length > 0) {
          this.purchaseType = JSON.parse(res.data[0].key_values)
        }
      })
    },
    // 新增
    add() {
      let _this = this
      this.dialogTitle = '新增申领单'
      this.dialogVisible_add = true
      this.disabled = false
      this.EditItem = {
        ly_date: _this.curDate,
      }
      if (this.userinfo.dept && this.userinfo.dept.length > 0) {
        this.EditItem.dept_name = this.userinfo.dept_name
        this.EditItem.dept_id = this.userinfo.dept_id
      } else {
        this.EditItem.dept_name = ''
        this.EditItem.dept_id = ''
      }
      this.arr_json = []
      this.files = []
      this.EditItem = {..._this.EditItem}
      this.files = [..._this.files]
      _this.$nextTick(() => {
        console.log(_this.arr_json)
        _this.$forceUpdate()
      })
    },

    uploadCover(e) {
      this.files.push({fileName: e.fileName, src: e.src, name: e.fileName, url: e.src})
    },
    delImage(idx) {
      this.files.splice(idx, 1)
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisibleImageUrl = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.files = fileList
    },
    uploadPreview(file) {
      // console.log(879,file)
      window.open(file.src || file.url)
    },
    refreshType() {
      console.log(463, 'refreshType')
      this.getType()
      this.dialogVisible_sxType = false
    },
    chooseHC(row) {
      let _this = this
      let obj = {}, arr = []
      obj = JSON.parse(JSON.stringify(row))
      arr = JSON.parse(JSON.stringify(_this.arr_json))
      obj.img = ''
      obj.cgTotal = 0
      if (arr && arr.length > 0) {
        if (_this.dep_Arr1(arr, obj.id)) {//dep_Arr1 id存在为false 不存在返回true
          arr.push(obj)
          _this.arr_json = arr
        } else {
          _this.$message.warning(`${obj.title}已存在,不要重复选择哦~`)
        }
      } else {
        arr.push(obj)
        _this.arr_json = arr
      }
      _this.$nextTick(() => {
        console.log(_this.arr_json)
        _this.$forceUpdate()
      })

    },
    dep_Arr1(arr, id) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.id == id) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },
    removeHC(idx) {
      let _this = this
      _this.arr_json.splice(idx, 1)
    },
    handleCGData(index) {
      console.log('handleCGData', index)
    },
    handleRemark() {
      console.log(this.arr_json)
    },
    handleExternalLinks() {
      console.log(this.arr_json)
    },
    uploadCover1(e, b, c, index) {
      // console.log(1082,e,b,c,index)
      let _this = this
      this.arr_json[index].img = e.src
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
      // this.files.push({fileName: e.fileName, src: e.src, name: e.fileName, url: e.src})
    },
    delImage1(index) {
      let _this = this
      this.arr_json[index].img = ''
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
    },

    editItem(row, flag) {
      let _this = this
      this.dialogTitle = '编辑'
      this.dialogVisible_add = true
      this.disabled = false

      let obj = JSON.parse(JSON.stringify(row))


      this.EditItem = {..._this.EditItem}


      obj.arr_json = obj.arr_json ? JSON.parse(obj.arr_json) : []
      obj.process_json = obj.process_json ? JSON.parse(obj.process_json) : []
      obj.files = obj.files ? JSON.parse(obj.files) : []
      obj.hc_list = obj.hc_list ? JSON.parse(obj.hc_list) : []

      this.EditItem = obj
      this.arr_json = obj.hc_list
      this.files = obj.files
      _this.$nextTick(() => {
        console.log(_this.arr_json)
        _this.$forceUpdate()
      })
    },
    view(row, type) {
      console.log(row, type)
      let obj = JSON.parse(JSON.stringify(row))
      obj.arr_json = obj.arr_json ? JSON.parse(obj.arr_json) : []
      obj.process_json = obj.process_json ? JSON.parse(obj.process_json) : []
      obj.files = obj.files ? JSON.parse(obj.files) : []
      obj.files_ck = obj.files_ck ? JSON.parse(obj.files_ck) : []
      // obj.hc_list = obj.hc_list ? JSON.parse(obj.hc_list) : []
      if (obj.hc_list) {
        let arr = JSON.parse(obj.hc_list)
        arr.map(a => {
          let links = []
          a.links = []
          if (a.externalLinks) {
            if (a.externalLinks.indexOf(';') > 0) {
              links = a.externalLinks.split(';')
              a.links = links
            } else if (a.externalLinks.indexOf('；') > 0) {
              links = a.externalLinks.split('；')
              a.links = links
            } else {
              a.links = [a.externalLinks]
            }
          }

        })
        obj.hc_list = arr
      }
      if(type == 'verify'){


      this.EditItem = obj
      this.dialogTitle = '申领单出库'
      this.dialogVisible_verify_ck = true
      console.log(this.EditItem.process_json)
      }else {
        this.EditItem = obj
        this.dialogTitle = '申领单详情'
        this.dialogVisible_detail = true
      }
    },
    qianshou(row) {
      console.log(row)
      this.$http.post("/api/zc_hc_sl_form", {
        id: row.id
      }).then(res => {
        console.log(88888, res.data)
        let obj = res.data
        obj.arr_json = obj.arr_json ? JSON.parse(obj.arr_json) : []
        obj.process_json = obj.process_json ? JSON.parse(obj.process_json) : []
        obj.files = obj.files ? JSON.parse(obj.files) : []
        obj.files_ck = obj.files_ck ? JSON.parse(obj.files_ck) : []
        if (obj.hc_list) {
          obj.hc_list && obj.hc_list.map(h => {
            h.ckNum = h.slNum
            h.img = h.ck_status == 1 ? h.img : ''
            h.signUrl = h.ck_status == 1 ? h.signUrl : ''
            h.ck_status = h.ck_status == 1 ? h.ck_status : 0
          })
        }
        this.EditItem = obj
        this.dialogTitle = '签收单'
        this.dialogVisible_verify_ck = true
      })
    },
    preCheckData(e) {

      let DataInfo = this.EditItem;


      if (this.needChoose && this.choosedUser.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择审批人'
        });
        this.$refs['processchoose'].choseSpUser({}, 0)
        return false;
      }
      return true

    },

    onSearch() {
      this.page.current_page = 1
      this.$refs.SXchukuTable.getList()

    },
    onReset() {
      this.queryForm = {}
      this.page.current_page = 1
      this.$refs.SXchukuTable.getList()

    },

    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },

    saveForm() {
      let flag = 1
      //--审批流程用----
      if (!this.preCheckData()) {
        return
      }
      //--审批流程用----
      let _this = this
      let params = {}
      console.log(this.EditItem)
      params = {..._this.EditItem}
      if (!params.ly_date) {
        _this.$message.warning('请填写领用日期~')
        return
      }
      if (!params.sl_remark) {
        _this.$message.warning('请填写申领原因~')
        return
      }
      if (!_this.arr_json || _this.arr_json == 0) {
        _this.$message.warning('请至少添加一条耗材~')
        return
      } else {
        _this.arr_json.map(a => {
          console.log(755755, a.slNum)
          if (!a.slNum) {
            flag = 0
            setTimeout(function () {
              _this.$message.warning(`请填写${a.title}的申领数量~`)
            }, 50)
            return
          }
        })
        params.hc_list = _this.arr_json
      }
      params.files = JSON.stringify(_this.files)

      //--审批流程用----
      params.sp_status = 0;
      params.process_id = this.process_id
      params.checkedUsers = this.choosedUser
      params.zc_type = 'sx'
      //--审批流程用----
      if (flag) {
        this.$http.post('/api/zc_hc_sl_edit', params).then(res => {
          _this.dialogVisible_add = false;
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          _this.$refs.SXchukuTable.getList()
        })
      }

    },


    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = (d.getMonth() + 1).toString().padStart(2, '0'); //获取当前月份(0-11,0代表1月)
      let date = (d.getDate()).toString().padStart(2, '0'); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },
    print(row){
      this.printId = row.id
      this.dialogVisible_print = true
      this.dialogTitle = '打印申领单'
    },
  }
}
</script>
<style lang="less" type="text/less">
.pagesxshenling {
  box-shadow: 0 0 5px #409eff81;
  padding-left: 10px;
  padding-bottom: 10px;
  position: relative;

  .addDialog {
    background-color: #fff;
    //border: 1px solid #409eff;
    box-shadow: 0 0 5px #409eff81;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: calc(100%);
    height: calc(100%);

    .el-dialog__wrapper {
      position: absolute !important;
    }

    .v-modal {
      position: absolute !important;
    }


    .myDialogBox {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: 0 !important;

      padding: 20px 20px 10px;

      .shadow {
        box-shadow: 0 0 5px #409eff81;
      }

      .titleBar {
        width: 100%;
        height: 30px;
        background-color: #2469f351;
        font-size: 14px;
        flex-wrap: wrap;
      }

      .titleBar .div {
        width: 100%;
        height: calc(100% - 2px);
        padding-left: 22px;
        border-left: none;
        color: #303133;
        font-weight: 700
      }

      .el-form-item {
        margin-bottom: 0;

        .el-textarea__inner {
          margin: 10px 0;
        }
      }

      .cgInput {
        max-width: 120px;

        .el-input__inner {
          color: #F56C6C;
        }
      }

      .cgInput input::-webkit-outer-spin-button,
      .cgInput input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      .cgInput input[type="number"] {
        -moz-appearance: textfield;
      }


    }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box,
  .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 0 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    //overflow: hidden;
    background-repeat: no-repeat;
    //cursor: pointer;
  }

  .uploadBox:hover {
    border-color: #409eff !important;
    color: #409eff;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}
</style>

